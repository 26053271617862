import React from 'react';
import { string, shape } from 'prop-types';
import classNames from 'classnames';
import Buybox from '../../../../features/buybox';
import PaymentSummary from '../../../../features/buybox/payment/payment.mobile';
import CouponSummary from '../../../../features/buybox/coupon-summary/coupon-summary.mobile';
import ShippingSummary from '../../../../features/buybox/shipping';
import SecondShippingSummary from '../../../../features/buybox/second-shipping';
import PickUpSummary from '../../../../features/buybox/pick-up';
import ReturnsSummary from '../../../../features/buybox/returns-summary/returns-summary.mobile';
import MeliplusLabel from '../../../../features/buybox/meliplus-label/meliplus-label';
import MeliplusSummary from '../../../../features/buybox/meliplus';
import UpsellingMeliplusSummary from '../../../../features/buybox/upselling-meliplus';
import CardWithThumbnails from '../../../../features/card-with-thumbnails/card-with-thumbnails';
import SellerInfo from '../../../../features/seller-info/seller-info.mobile';
import StockInformation from '../../../../features/stock-information/stock-information.mobile';
import FulfillmentInformation from '../../../../features/fulfillment-information';
import Quantity from '../../../../features/buybox/quantity/quantity.mobile';
import Actions from '../../../../features/buybox/actions';
import CartActions from '../../../../features/buybox/cart-actions/cart-actions.mobile';
import CreditsTooltip from '../../../../features/credits-tooltip';
import PaypalTooltip from '../../../../features/paypal-tooltip';
import MainActions from '../../../../../vip/features/main-actions/main-actions.mobile';
import CpgSummary from '../../../../features/cpg/summary.mobile';
import ShippingMessage from '../../../../features/shipping-message';
import MoqInformation from '../../../../features/moq-information/moq-information.mobile';
import CbtSummary from '../../../../../../components/cbt/cbt-summary.mobile';
import CbtTaxesSummary from '../../../../../../components/cbt-taxes/cbt-taxes-summary.mobile';
import ThirdPartyFirstSection from '../../../../../vip/features/third_party_first_section';
import GenericBanner from '../../../../features/buybox/generic-banner/generic-banner.mobile';
import GenericBannerPrice from '../../../../features/buybox/generic-banner-price/generic-banner.mobile';
import GenericBannerInstallments from '../../../../features/buybox/generic-banner-installments/generic-banner.mobile';
import DynamicComponentWrapper from '../../../../../vip/features/dynamic-component-wrapper';
import renderSwapComponent from '../../../../../vip/features/dynamic-component-wrapper/render-swap-component';

const Grid = require('../../../../../../components/grid');

const MeliDeliveryDaySummary = () => (
  <>
    <MeliplusSummary />
    <CardWithThumbnails className="mb-20 mt-4" />
  </>
);

// Dynamic Shipping Wrapper (for order swap)
const renderTopShipping = renderSwapComponent(
  'meliplus_summary',
  <MeliDeliveryDaySummary />,
  <ShippingSummary className="mb-12" />,
);

const BuyBoxChunk = ({ baseURL, site, components }) => {
  const { moq_information } = components || {};
  const isMshops = site === 'MS';
  const hasMoqInfo = moq_information && moq_information !== 'HIDDEN';

  return (
    <Grid.Row>
      <Buybox className="pl-16 pr-16">
        <CouponSummary className="mb-24" />
        <PaymentSummary className="mb-15" />
        <GenericBannerInstallments className="mb-24" />
        <GenericBannerPrice className="mb-24" />
        <CpgSummary />
        <CbtSummary className="mb-20" />
        <MeliplusLabel className="ui-pdp-meliplus-label mb-12" />
        <ShippingMessage className="mb-14" />
        <DynamicComponentWrapper renderDynamicComponent={renderTopShipping} spot="top_shipping" />
        <CbtTaxesSummary />
        <SecondShippingSummary className="mb-12" />
        <GenericBanner className="mb-16" />
        <UpsellingMeliplusSummary className="mb-12" />
        <PickUpSummary className="mt-20 mb-12" />
        <ReturnsSummary className="mb-12" />
        <SellerInfo className="mb-25" />
        <div className="ui-pdp-stock-and-full mt-24 mb-24">
          <StockInformation />
          <FulfillmentInformation />
        </div>
        <ThirdPartyFirstSection />
        <Quantity className={classNames('mb-25', { 'ui-quantity--with-moq': hasMoqInfo })} />
        <MoqInformation className="mb-16" />
        <PaypalTooltip className="mb-16" />
        {isMshops ? <MainActions /> : <Actions className="mt-30" parentUrl={baseURL} />}
        <CartActions />
        <CreditsTooltip className="mt-16" />
      </Buybox>
    </Grid.Row>
  );
};

BuyBoxChunk.propTypes = {
  baseURL: string.isRequired,
  site: string,
  components: shape({}),
};

BuyBoxChunk.defaultProps = {
  site: 'ML',
  components: null,
};

export default BuyBoxChunk;
