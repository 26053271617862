const { loadable } = require('nordic/lazy');
const compose = require('../../../../lib/compose');
const connect = require('../../../../store/connect');
const withFeatureFlag = require('../../../../components/feature-flag/with-feature-flag');

const BillboardAdn = loadable(() => import('../../../../components/advertising-adn'));

const FEATURE_NAME = 'billboard_context_adn';

const mapStateToProps = ({
  components: {
    [FEATURE_NAME]: { type, content, title },
  },
}) => ({ type, content, title });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

module.exports = enhance(BillboardAdn);
