import React from 'react';
import ApparelSizeSpec from '../../../../features/appareal-size-spec/apparel-size-spec.mobile';

const Grid = require('../../../../../../components/grid');

const ApparelSizeSpecChunk = () => (
  <>
    <Grid.Row className="mt-12">
      <ApparelSizeSpec className="pl-16 pr-16" />
    </Grid.Row>
  </>
);

export default ApparelSizeSpecChunk;
