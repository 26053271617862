import React from 'react';
import { string, shape } from 'prop-types';
import { LazyHydrate as Hydrator } from 'nordic/lazy';

/* Base Page */
import PdP from './main';

import CPG from '../../../features/cpg/cpg.mobile';
import Header from '../../../features/header/header.mobile';
import HeaderTopBrand from '../../../features/header-top-brand/header-top-brand.mobile';
import HeaderBrandLogo from '../../../features/header-brand-logo/header_brand_logo.mobile';
import Highlights from '../../../features/highlights';
import Promotions from '../../../features/promotions';
import PromotionBottom from '../../../features/promotion-bottom';
import Gallery from '../../../features/gallery/gallery.mobile';
import VariationsChunk from './chunk/variations.mobile';
import VariationsBoxChunk from './chunk/variations-box.mobile';
import BuyBoxChunk from './chunk/buybox.mobile';
import ReturnChunk from './chunk/return.mobile';
import AboveRecommendationsChunk from './chunk/above-recommendations.mobile';
import HighlightedSpecsChunk from './chunk/highlighted-specs.mobile';
import HighlightedSpecsSwapChunk from './chunk/highlighted-specs-swap.mobile';
import HighlightedSpecsThirdSwapChunk from './chunk/highlighted-specs-third-swap.mobile';
import { RecommendationsTopChunk } from './chunk/recommendations.mobile';
import RecommendationsMiddleChunk from './chunk/recommendations-middle.mobile';
import SellerInfoChunk from './chunk/seller-info.mobile';
import SellerDataChunk from './chunk/seller-data.mobile';
import PaymentsChunk from './chunk/payments.mobile';
import QuestionsChunk from './chunk/questions.mobile';
import AdsChunk from './chunk/ads.mobile';
import RelatedSearches from '../../../features/related-searches';
import BreadcrumbAndRelatedReviewChunk from './chunk/breadcrumb-related-reviews.mobile';
import OnboardingChunk from './chunk/onboarding.mobile';
import ApparelSizeSpec from './chunk/apparel-size-spec.mobile';
import BillboardAdn from '../../../../vip/features/billboard-adn/billboard-adn-bottom';
import BillboardAdnUp from '../../../../vip/features/billboard-adn/billboard-adn-up';
import OfficialStores from './chunk/official-stores.mobile';
import TradeInChunk from './chunk/trade-in.mobile';
import DynamicComponentWrapper from '../../../../vip/features/dynamic-component-wrapper';
import HeaderOfficialStore from '../../../features/header-official-store/header-official-store.mobile';
import ExitPopUpWebMobile from '../../../features/exit-popup-web-mobile/exit-popup-web-mobile';
import PreloadIframeChunk from './chunk/preload-iframe.mobile';
import SnackbarChunk from './chunk/snackbar';
import Reputation from '../../../features/reputation-seller';
import SellerExperiment from '../../../features/seller-info/seller-experiment.mobile';
import PriceChunk from './chunk/price.mobile';
import BuyBoxOffersChunk from '../../../features/buy-box-offers';
import WarningPromotionsChunk from './chunk/warning-promotions.mobile';
import RecommendationsCarouselByType from '../../../../vip/features/recommendations/carousel-byType';
import RecommendationsCarouselFreeHorizontal from '../../../features/recommendations/carousel-free-horizontal';
import RecommendationsCarouselFree from '../../../../vip/features/recommendations/carousel-free';
import OnDemandIframe from '../../../features/on-demand-iframe/on-demand-iframe';
import Denounce from '../../../features/denounce';
import DescriptionChunk from './chunk/description.mobile';
import ApparelSizeSpecPreviewChunk from './chunk/apparel-size-spec-preview.mobile';
import VerticalGalleryChunk from './chunk/vertical-gallery.mobile';
import InstalationService from '../../../features/instalation-service';

/* Components */
const Grid = require('../../../../../components/grid');

const PdPMobile = ({ baseURL, siteId, initialState, ...rest }) => (
  <PdP baseURL={baseURL} siteId={siteId} initialState={initialState} {...rest}>
    <div className="ui-pdp">
      <OnDemandIframe />
      <Hydrator whenIdle>
        <HeaderOfficialStore />
      </Hydrator>
      <Hydrator whenIdle>
        <PreloadIframeChunk />
      </Hydrator>
      <Grid.Container className="ui-pdp-container--pdp">
        <Hydrator whenVisible>
          <OnboardingChunk />
        </Hydrator>
        <Hydrator whenIdle>
          <CPG />
        </Hydrator>
        <Hydrator whenIdle>
          <RecommendationsCarouselFreeHorizontal
            featureName="carousel_pom_top"
            className="ui-pdp-carousel-cheaper"
            lazy={false}
            preload
            fetchPriority="high"
            criticalImages={1}
          />
        </Hydrator>
        <Hydrator whenIdle>
          <HeaderBrandLogo />
        </Hydrator>
        <Grid.Row>
          <Hydrator whenIdle>
            <HeaderTopBrand />
          </Hydrator>
          <Hydrator whenIdle>
            <Header>
              <Highlights className="ui-pdp-highlights" mobile />
            </Header>
          </Hydrator>
        </Grid.Row>
        <Grid.Row className="mt-16">
          <Gallery />
        </Grid.Row>
        <Hydrator whenVisible>
          <VariationsBoxChunk />
        </Hydrator>
        <Hydrator whenVisible>
          <ApparelSizeSpec />
        </Hydrator>
        <Hydrator whenVisible>
          <VariationsChunk />
        </Hydrator>
        <Hydrator whenVisible>
          <InstalationService />
        </Hydrator>
        <Hydrator whenVisible>
          <TradeInChunk />
        </Hydrator>
        <Hydrator whenVisible>
          <WarningPromotionsChunk />
        </Hydrator>
        <Hydrator whenVisible>
          <PriceChunk />
        </Hydrator>
        <Hydrator whenVisible>
          <BuyBoxOffersChunk className="mt-24 ml-16 mr-16" />
        </Hydrator>
        <Hydrator whenVisible>
          <PromotionBottom className="ml-16" mobile />
        </Hydrator>
        <Hydrator whenVisible>
          <Promotions className="ml-16 mt-12" mobile />
        </Hydrator>
        <Hydrator whenVisible>
          <BuyBoxChunk baseURL={baseURL} />
        </Hydrator>
        <Hydrator whenVisible>
          <Reputation />
        </Hydrator>
        <Hydrator whenVisible>
          <SellerExperiment className="mt-24 ml-16 mr-16" />
        </Hydrator>
        <Hydrator whenVisible>
          <ReturnChunk />
        </Hydrator>
        <Hydrator whenVisible>
          <AboveRecommendationsChunk />
        </Hydrator>
        <Hydrator whenVisible>
          <HighlightedSpecsChunk /> {/* FT con highlights (formato actual) */}
        </Hydrator>
        <Hydrator whenIdle>
          <RecommendationsTopChunk />
        </Hydrator>
        <Hydrator whenVisible>
          <SellerInfoChunk />
        </Hydrator>
        <SellerDataChunk />
        <Hydrator whenVisible>
          <ApparelSizeSpecPreviewChunk />
        </Hydrator>
        <Hydrator whenVisible>
          <HighlightedSpecsSwapChunk /> {/* Swap entre la FT a deprecar y la del formato actual <<experimento>> */}
        </Hydrator>
        <Hydrator whenVisible>
          <VerticalGalleryChunk />
        </Hydrator>
        <Hydrator whenVisible>
          <HighlightedSpecsThirdSwapChunk /> {/* Only show in Fashion */}
        </Hydrator>
        <Hydrator whenVisible>
          <DescriptionChunk />
        </Hydrator>
        <Hydrator whenVisible>
          <RecommendationsMiddleChunk />
        </Hydrator>
        <Hydrator whenVisible>
          <PaymentsChunk />
        </Hydrator>
        <Hydrator ssrOnly>
          <BillboardAdnUp />
        </Hydrator>
        <Hydrator whenVisible>
          <QuestionsChunk components={initialState.components} />
        </Hydrator>
        <Hydrator whenVisible>
          <OfficialStores />
        </Hydrator>
      </Grid.Container>
      {/* Content Bottom */}
      <div className="ui-pdp-container--bottom">
        <Hydrator whenVisible>
          <Denounce />
        </Hydrator>
        <Grid.Container className="ui-recommendations pdp-mobile-recos-ads">
          <Hydrator whenIdle>
            <DynamicComponentWrapper
              renderDynamicComponent={featureName => (
                <RecommendationsCarouselFree className="mb-64" featureName={featureName} />
              )}
              spot="carousel_bottom"
            />
          </Hydrator>
        </Grid.Container>
        <Hydrator whenIdle>
          <AdsChunk />
        </Hydrator>
        <Hydrator ssrOnly>
          <BillboardAdn />
        </Hydrator>
        <Hydrator whenIdle>
          <RecommendationsCarouselByType featureName="grid_v2p" className="m-16" />
        </Hydrator>
        <Hydrator whenVisible>
          <RelatedSearches />
        </Hydrator>
        <Hydrator whenVisible>
          <BreadcrumbAndRelatedReviewChunk />
        </Hydrator>
        <Hydrator whenIdle>
          <SnackbarChunk />
        </Hydrator>
      </div>
      <ExitPopUpWebMobile url={initialState.parentUrl} />
    </div>
  </PdP>
);

PdPMobile.propTypes = {
  baseURL: string.isRequired,
  initialState: shape({}).isRequired,
  siteId: string.isRequired,
};

export default PdPMobile;
