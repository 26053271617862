import { loadable } from 'nordic/lazy';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';

const Onboarding = loadable(() => import('../../../../components/onboarding/onboarding.mobile'));

const FEATURE_NAME = 'onboarding';

const mapStateToProps = ({ components: { [FEATURE_NAME]: onboarding } }) => ({ ...onboarding });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(Onboarding);
