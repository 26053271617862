import React from 'react';
import { bool } from 'prop-types';
import GroupedShareBookmark from '../../../../features/grouped-share-bookmark';
import GiftRegistry from '../../../../../vip/features/gift-registry';
import OtherSellersNewItems from '../../../../features/other-sellers-new-items';
import OtherSellersUsedRefurbished from '../../../../features/other-sellers-used-refurbished';
import MerchEcosystem from '../../../../features/merch-ecosystem';

const Grid = require('../../../../../../components/grid');

const AboveRecommendationsChunk = ({ fromMShops }) => (
  <>
    <Grid.Row>
      {!fromMShops && (
        <Grid.Col
          className=" ui-pdp-with--separator ui-pdp-with--separator--medium-top mt-20"
          id="GroupedShareBookmark"
        >
          <GiftRegistry className="mt-24" />
          <GroupedShareBookmark showOnlyForWishlistVariant />
        </Grid.Col>
      )}
    </Grid.Row>
    <Grid.Row className="ui-pdp-container__col--text-center ui-pdp-with--separator ui-pdp-with--separator--medium-top">
      <OtherSellersNewItems className="mt-24" />
      <OtherSellersUsedRefurbished className="mt-24" />
    </Grid.Row>
    <Grid.Row className="ui-pdp-with--separator">
      <MerchEcosystem />
    </Grid.Row>
  </>
);

AboveRecommendationsChunk.propTypes = {
  fromMShops: bool,
};

AboveRecommendationsChunk.defaultProps = {
  fromMShops: false,
};

export default AboveRecommendationsChunk;
