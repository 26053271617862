const React = require('react');
const { string, shape } = require('prop-types');
const classnames = require('classnames');
const BaseModal = require('../modals/base-modal/index');
const { trackEvent } = require('../../lib/tracking');
const ExitPopupWebMobileTitle = require('./exit-popup-web-mobile-title');
const { Button } = require('@andes/button');
const Close = require('@andes/icons/Close24');
const { setCookie } = require('../../lib/cookies');
const RecommendationsListDouble = require('../../pages/vip/features/recommendations/list-double').default;

const { useState, useEffect, useMemo, useCallback } = React;

const namespace = 'ui-pdp-exit-popup-web-mobile';
const name_history_push = 'exit-popup-push-history';
const cookie_name = 'ml_gads_exit-popup-offer';

const ExitPopupWebMobileComponent = ({ url, state, track, carousel_config }) => {
  const { title, subtitle = {} } = carousel_config?.recommendation_from_middle_end;
  const isTrackHidden = state === 'TRACK_HIDDEN';
  const isHidden = state === 'HIDDEN';

  const [openModal, setOpenModal] = useState(false);
  const [sendTrack, setSendTrack] = useState(false);

  const cookie_attributes = useMemo(
    () => ({
      path: '/',
      domain: `.${new URL(url).hostname
        .split('.')
        .slice(-3)
        .join('.')}`,
    }),
    [url],
  );

  const send_track = trackParam => {
    setSendTrack(true);
    trackEvent(trackParam);
  };

  const getDateNow = () => {
    const dateNow = new Date();
    return dateNow.toISOString();
  };

  const handlePopState = useCallback(event => {
    if (!sendTrack && event.state !== name_history_push) {
      if (!isTrackHidden) {
        setOpenModal(true);
      }
      setCookie(cookie_name, getDateNow(), cookie_attributes);
      send_track(track);
      if (isTrackHidden) {
        window.history.back();
      }
    }
  }, [sendTrack, isTrackHidden, track, cookie_attributes]);

  const CloseModal = () => {
    setOpenModal(false);
    window.history.back();
  };

  useEffect(() => {
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [handlePopState]);

  useEffect(() => {
    if ((!isTrackHidden || !isHidden) && window.history.state !== name_history_push) {
      window.history.pushState(name_history_push, null, window.location.href);
    }
  }, [isTrackHidden, isHidden]);

  if (isTrackHidden || isHidden) {
    return null;
  }

  return (
    <BaseModal
      key={`${namespace}__modal-${Math.random()}`}
      id={`${namespace}__modal`}
      isOpen={openModal}
      onClose={() => CloseModal()}
      closable={false}
      type="large"
      autoHeight={false}
      className={classnames(`${namespace}__bg-modal-exit-popup`)}
    >
      <div className={classnames(`${namespace}__div-modal-container`)}>
        <div className={classnames(`${namespace}__div-close-button-container`)}>
          <Button onClick={() => CloseModal()} style={{ padding: 0 }} hierarchy="transparent" className="active">
            <Close />
          </Button>
        </div>
        <>
          <ExitPopupWebMobileTitle title={title} namespace={namespace} />
        </>
        <div className={classnames(`${namespace}__div-modal-list-container`)}>
          <RecommendationsListDouble
            featureName="carousel_exit_pop_up"
            className={`${namespace}-recommendations-list`}
          />
        </div>
        <div className={classnames(`${namespace}__div-modal-button`)}>
          <Button hierarchy="loud" className={classnames(`${namespace}__exit-popup-button`)} href={`${subtitle.url}`}>
            {subtitle.text}
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};

ExitPopupWebMobileComponent.propTypes = {
  url: string.isRequired,
  state: string.isRequired,
  track: shape({ melidata_event: shape({}) }),
  carousel_config: shape({ recommendation_from_middle_end: shape({ title: string, subtitle: shape({}) }) }),
};

ExitPopupWebMobileComponent.defaultProps = {
  isOpen: true,
  url: '',
  state: '',
  carousel_config: { recommendation_from_middle_end: { title: '', subtitle: {} } },
};

module.exports = ExitPopupWebMobileComponent;
