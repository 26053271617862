import React from 'react';
import SellerInfo from '../../../../features/seller/seller-info.mobile';

const Grid = require('../../../../../../components/grid');

const SellerInfoChunk = () => (
  <Grid.Row className="ui-pdp-with--separator ui-pdp-with--separator--medium-top">
    <SellerInfo />
  </Grid.Row>
);

export default SellerInfoChunk;
