import compose from '../../../../../lib/compose';
import connect from '../../../../../store/connect';
import withFeatureFlag from '../../../../../components/feature-flag/with-feature-flag';
import BannerMobile from '../../../../../components/buybox/banner/banner.mobile';
import actions from '../../../../../actions';
import { reloadPage } from './utils';

const FEATURE_NAME = 'generic_banner_price';

const GENERIC_BANNER_PROPS = {
  id: FEATURE_NAME,
};

const mapStateToProps = ({ isFetching, components: { [FEATURE_NAME]: genericBannerPrice } }, ownProps) => ({
  isFetching,
  ...genericBannerPrice,
  ...ownProps,
  ...GENERIC_BANNER_PROPS,
});

const mapDispatchToProps = dispatch => ({
  onCloseModal: () => {
    dispatch(actions.fetchProductOnMeliplusUpdate('close_modal'));
  },
  meliplusUpsellingOnSuscription: () => {
    reloadPage();
  },
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(BannerMobile);
export { mapDispatchToProps };
