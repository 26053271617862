import React from 'react';
import VerticalGallery from '../../../../features/vertical-gallery/vertical-gallery.mobile';

const Grid = require('../../../../../../components/grid');

const VerticalGalleryChunk = () => (
  <Grid.Row className="ui-pdp-with--separator">
    <VerticalGallery className="pl-16 pr-16" />
  </Grid.Row>
);

export default VerticalGalleryChunk;
