import React from 'react';
import Breadcrumb from '../../../../features/breadcrumb';

const BreadcrumbAndRelatedReviewChunk = () => (
  <>
    <Breadcrumb mode="compact" />
  </>
);

export default BreadcrumbAndRelatedReviewChunk;
