import React from 'react';
import Variations from '../../../../features/variations/variations.mobile';

const Grid = require('../../../../../../components/grid');

const VariationChunk = () => (
  <>
    <Grid.Row>
      <Variations className="mt-15 pl-16 pr-16" />
    </Grid.Row>
  </>
);

export default VariationChunk;
