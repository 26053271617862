import { loadable } from 'nordic/lazy';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';

const HeaderOfficialStore = loadable(() => import('../../../../components/header-official-store/header-mobile'));

const FEATURE_NAME = 'header_official_store';

const mapStateToProps = ({ components: { [FEATURE_NAME]: props } }) => ({
  ...props,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(HeaderOfficialStore);
