import React from 'react';
import RecommendationsCarouselFree from '../../../../../vip/features/recommendations/carousel-free';
import RecommendationsCarouselByType from '../../../../../vip/features/recommendations/carousel-byType';
import RecommendationsCombos from '../../../../../../components/combos/combos.mobile';
import RecommendationsListDouble from '../../../../../vip/features/recommendations/list-double';

const RecommendationsTopChunk = () => (
  <>
    <RecommendationsCombos />
    <RecommendationsCarouselByType featureName="carousel_above" className="ui-pdp-with--separator" />
    <RecommendationsListDouble featureName="carousel_above_seller" className="ui-pdp-with--separator list-double-vpp" />
    <RecommendationsCarouselFree featureName="carousel_up" className="ui-pdp-with--separator" />
  </>
);

export default RecommendationsTopChunk;
