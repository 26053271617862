const React = require('react');
const classnames = require('classnames');
const { string } = require('prop-types');

const ExitPopupWebMobileTitleComponent = ({ namespace, title }) => (
  <div className={classnames(`${namespace}__div-modal-title`)}>
    <div className={classnames(`${namespace}__div-exit-popup-title`)}>
      <p className={classnames(`${namespace}__title`)}>{title}</p>
    </div>
  </div>
);

ExitPopupWebMobileTitleComponent.propTypes = {
  namespace: string.isRequired,
  title: string.isRequired,
};

ExitPopupWebMobileTitleComponent.defaultProps = {
  namespace: 'ui-pdp-exit-popup-web-mobile',
  title: '',
};

module.exports = ExitPopupWebMobileTitleComponent;
