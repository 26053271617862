import React from 'react';
import Returns from '../../../../features/returns/returns.mobile';
import Benefits from '../../../../features/benefits';
import OtherProducts from '../../../../features/other-products/other-products.mobile';

const Grid = require('../../../../../../components/grid');

const ReturnChunk = () => (
  <>
    <Grid.Row>
      <Returns className="mt-24 pl-16 pr-16 mb-24" />
      <Benefits className="mt-20 pl-16 pr-16 mb-12" />
    </Grid.Row>
    <Grid.Row>
      <OtherProducts className="mt-20 pl-16 pr-16 mb-12" />
    </Grid.Row>
  </>
);

export default ReturnChunk;
