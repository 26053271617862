import React from 'react';
import RecommendationsComparator from '../../../../features/recommendations/comparator.mobile';
import DynamicComponentWrapper from '../../../../../vip/features/dynamic-component-wrapper';
import { spotMiddleMobileRender } from '../../../../../../utils/spotMiddleRenders';

const Grid = require('../../../../../../components/grid');

const RecommendationsMiddleChunk = () => (
  <>
    <Grid.Row>
      <DynamicComponentWrapper
        renderDynamicComponent={spotMiddleMobileRender({
          firstChildClassName: 'mb-40',
          lastChildClassName: 'ui-pdp-with--separator',
          middleChildClassName: 'mt-40 mb-40',
        })}
        className="ui-pdp-spot-middle"
        spot="spot_middle"
      />
    </Grid.Row>
    <Grid.Row className="ui-pdp-with--separator pdp-mobile-recos--comparator">
      <RecommendationsComparator />
    </Grid.Row>
  </>
);

export default RecommendationsMiddleChunk;
