import React from 'react';
import PreloadIframe from '../../../../features/preload-iframe/preload-iframe';

const Grid = require('../../../../../../components/grid');

const PreloadIframeChunk = () => (
  <Grid.Row>
    <PreloadIframe className="pt-24" />
  </Grid.Row>
);

export default PreloadIframeChunk;
