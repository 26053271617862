import React from 'react';
import SellerData from '../../../../features/seller-data';

const Grid = require('../../../../../../components/grid');

const SellerDataChunk = () => (
  <Grid.Row className="ui-pdp-with--separator ui-pdp-with--separator--medium-top">
    <SellerData className="ui-box-component ui-box-component-pdp__visible--mobile" />
  </Grid.Row>
);

export default SellerDataChunk;
