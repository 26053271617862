import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import mapStateToProps from '../../../../components/recommendations/state-mapper-base';
import RecommendationsCarouselFreeHorizontal from '../../../../components/recommendations/carousel-free-horizontal';

const enhance = compose(
  withFeatureFlag(),
  connect(mapStateToProps()),
);

export default enhance(RecommendationsCarouselFreeHorizontal);
