import React from 'react';
import TradeIn from '../../../../features/trade-in';

const Grid = require('../../../../../../components/grid');

const TradeInChunk = () => (
  <>
    <Grid.Row className="pl-16 pr-16">
      <TradeIn />
    </Grid.Row>
  </>
);

export default TradeInChunk;
