import React from 'react';
import HighlightedSpecsFeaturesThirdSwap from '../../../../features/highlighted-specs-third-swap/highlighted-specs-features.mobile';
import HighlightedSpecsAttrsThirdSwap from '../../../../features/highlighted-specs-third-swap/highlighted-specs-attrs.mobile';

const Grid = require('../../../../../../components/grid');

const HighlightedSpecsThirdSwapChunk = () => (
  <>
    <HighlightedSpecsFeaturesThirdSwap />
    <Grid.Row className="ui-pdp-html-description ui-pdp-with--separator">
      <HighlightedSpecsAttrsThirdSwap />
    </Grid.Row>
  </>
);

export default HighlightedSpecsThirdSwapChunk;
