import React from 'react';
import ApparelSizeSpecPreview from '../../../../features/apparel-size-spec-preview/apparel-size-specs-preview.mobile';

const Grid = require('../../../../../../components/grid');

const ApparelSizeSpecPreviewChunk = () => (
  <Grid.Row className="ui-pdp-with--separator">
    <ApparelSizeSpecPreview />
  </Grid.Row>
);

export default ApparelSizeSpecPreviewChunk;
