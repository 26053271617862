import React from 'react';
import Price from '../../../../features/price';
import CbtTaxesFcUsSummary from '../../../../../../components/cbt-taxes-fc-us/cbt-taxes-fc-us-summary.mobile';

const Grid = require('../../../../../../components/grid');

const PriceChunk = () => (
  <>
    <Grid.Row>
      <Price meta className="pb-24" size="large" />
      <CbtTaxesFcUsSummary />
    </Grid.Row>
  </>
);

export default PriceChunk;
