import { loadable } from 'nordic/lazy';
import { withBoxComponent } from '../../../../components/box-component/with-box-component.mobile';
import apparelSizeSpecsPreview from './apparel-size-specs-preview'; // Asegúrate de que 'apparelSizeSpecsPreview' es exportado por defecto desde './apparel-size-specs-preview'

const ApparelSizeSpecsPreview = loadable(() =>
  import('../../../../components/apparel-size-specs-preview/apparel-size-specs-preview.mobile'),
);

const ApparelSizeSpecsPreviewBox = withBoxComponent()(ApparelSizeSpecsPreview);

const configuredApparelSizeSpecsPreview = apparelSizeSpecsPreview(ApparelSizeSpecsPreviewBox);
export default configuredApparelSizeSpecsPreview;
