import React from 'react';
import { shape } from 'prop-types';
import classNames from 'classnames';
import Questions from '../../../../features/questions/questions.mobile';
import QADB from '../../../../features/qadb';
import ProductReviews from '../../../../features/reviews/reviews.mobile';
import ReviewsCapability from '../../../../../../components/reviews-capability/reviews.mobile';

// Utils
import { isReviewsRowVisible } from '../../../../../../utils/component-visibility';

const Grid = require('../../../../../../components/grid');

// eslint-disable-next-line react/no-object-type-as-default-prop
const QuestionsChunk = ({ components = {} }) => {
  const { reviews, reviews_capability_v3 } = components;

  const hasReviewsRow = isReviewsRowVisible({ reviews, reviews_capability_v3 });
  const questionsContainerClassName = classNames(
    { 'ui-pdp-with--separator': hasReviewsRow },
    { 'mb-40': !hasReviewsRow },
  );

  return (
    <>
      <Grid.Row className={questionsContainerClassName}>
        <Questions className="pl-16 pr-16" />
        <QADB className="pl-16 pr-16" />
      </Grid.Row>
      {reviews && (
        <Grid.Row>
          <ProductReviews />
        </Grid.Row>
      )}
      {reviews_capability_v3 && <ReviewsCapability />}
    </>
  );
};

QuestionsChunk.propTypes = {
  components: shape({}).isRequired,
};

export default QuestionsChunk;
