import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';

const FEATURE_NAME = 'carousel_exit_pop_up';

const mapStateToProps = ({ components: { [FEATURE_NAME]: props } }, ownProps) => ({ ...props, ...ownProps });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);
const exitPopUpWebMobile = ExitPopUpWebMobile => enhance(ExitPopUpWebMobile);

export default exitPopUpWebMobile;
