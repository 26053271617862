import React from 'react';
import Description from '../../../../features/description/description.mobile';
import HtmlDescription from '../../../../features/html-description/html-description.mobile';

const Grid = require('../../../../../../components/grid');

const DescriptionChunk = () => (
  <Grid.Row className="ui-pdp-with--separator">
    <Description className="pl-16 pr-16" />
    <HtmlDescription className="pl-16 pr-16" />
  </Grid.Row>
);

export default DescriptionChunk;
