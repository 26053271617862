import React from 'react';
import PaymentMethods from '../../../../features/payment-methods/payment-methods.mobile';

const Grid = require('../../../../../../components/grid');

const PaymentsChunk = () => (
  <Grid.Row className="ui-pdp-with--separator">
    <PaymentMethods className="pt-24" />
  </Grid.Row>
);

export default PaymentsChunk;
