/**
 * Styles
 */
require('../../../pages/pdp/styles/pdp.mobile.scss');

/**
 * Module dependencies
 */
const bootstrap = require('../../bootstrap');
const PdP = require('../../../pages/pdp/components/views/main/main.mobile').default;

bootstrap(PdP);
