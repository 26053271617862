import React from 'react';
import Specs from '../../../../features/specs/specs.mobile';
import HighlightedSpecsAttrsSwap from '../../../../features/highlighted-specs-swap/highlighted-specs-attrs.mobile';

const Grid = require('../../../../../../components/grid');

const HighlightedSpecsSwapChunk = () => (
  <>
    <Grid.Row className="ui-pdp-html-description ui-pdp-with--separator">
      <HighlightedSpecsAttrsSwap />
    </Grid.Row>
    <Grid.Row>
      <Specs className="pl-16 pr-16" />
    </Grid.Row>
  </>
);

export default HighlightedSpecsSwapChunk;
