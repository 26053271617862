import React from 'react';
import WarningMessage from '../../../../features/warning-message';
import PromotionTop from '../../../../features/promotion-top';

const Grid = require('../../../../../../components/grid');

const WarningPromotionsChunk = () => (
  <>
    <Grid.Row>
      <Grid.Col className="ui-pdp-with--separator ui-pdp-with--separator--medium-top">
        <WarningMessage className="mt-24 mb-24 ml-16 mr-16" />
      </Grid.Col>
    </Grid.Row>
    <Grid.Row>
      <PromotionTop className="ml-16 mt-24" />
    </Grid.Row>
  </>
);

export default WarningPromotionsChunk;
