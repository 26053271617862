import { loadable } from 'nordic/lazy';
import { withBoxComponent } from '../../../../components/box-component/with-box-component.mobile';
import sellerInfo from './seller-info';

const SellerInfo = loadable(() => import('../../../../components/seller-info/seller-info'));

const SellerInfoBox = withBoxComponent()(SellerInfo);

const configuredSellerInfoBox = sellerInfo(SellerInfoBox);
export default configuredSellerInfoBox;
