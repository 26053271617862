import React from 'react';
import FullscreenAd from '../../../../features/fullscreen-ad';

const Grid = require('../../../../../../components/grid');

const AdsChunk = () => (
  <Grid.Container>
    <FullscreenAd />
  </Grid.Container>
);

export default AdsChunk;
