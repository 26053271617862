import React from 'react';
import HighlightedSpecsFeatures from '../../../../features/highlighted-specs/highlighted-specs-features.mobile';
import HighlightedSpecsAttrs from '../../../../features/highlighted-specs/highlighted-specs-attrs.mobile';

const Grid = require('../../../../../../components/grid');

const HighlightedSpecsChunk = () => (
  <>
    <HighlightedSpecsFeatures />
    <Grid.Row className="ui-pdp-html-description ui-pdp-with--separator">
      <HighlightedSpecsAttrs />
    </Grid.Row>
  </>
);

export default HighlightedSpecsChunk;
