import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import VerticalGalleryMobile from '../../../../components/vertical-gallery/vertical-gallery.mobile';
import jsonToProps from '../../../../components/vertical-gallery/json-to-props';

const FEATURE_NAME = 'vertical_gallery';

const mapStateToProps = ({ id, components: { [FEATURE_NAME]: vertical_gallery } }, { className }) => ({
  ...jsonToProps({ ...vertical_gallery }),
  id,
  className,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(VerticalGalleryMobile);
