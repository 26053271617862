import React from 'react';
import VariationsBox from '../../../../features/variations/variations-box.mobile';

const Grid = require('../../../../../../components/grid');

const VariationBoxChunk = () => (
  <>
    <Grid.Row className="mt-15">
      <VariationsBox className="pl-16 pr-16" />
    </Grid.Row>
  </>
);

export default VariationBoxChunk;
